@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Lexend Deca';
    font-style: normal;
    font-weight: 200;
    src: url('assets/fonts/lexend-deca-v21-latin_latin-ext-200.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* lexend-deca-300 - latin_latin-ext */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Lexend Deca';
    font-style: normal;
    font-weight: 300;
    src: url('assets/fonts/lexend-deca-v21-latin_latin-ext-300.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* lexend-deca-regular - latin_latin-ext */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Lexend Deca';
    font-style: normal;
    font-weight: 400;
    src: url('assets/fonts/lexend-deca-v21-latin_latin-ext-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* lexend-deca-500 - latin_latin-ext */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Lexend Deca';
    font-style: normal;
    font-weight: 500;
    src: url('assets/fonts/lexend-deca-v21-latin_latin-ext-500.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* lexend-deca-600 - latin_latin-ext */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Lexend Deca';
    font-style: normal;
    font-weight: 600;
    src: url('assets/fonts/lexend-deca-v21-latin_latin-ext-600.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* lexend-deca-700 - latin_latin-ext */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Lexend Deca';
    font-style: normal;
    font-weight: 700;
    src: url('assets/fonts/lexend-deca-v21-latin_latin-ext-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* lexend-deca-800 - latin_latin-ext */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Lexend Deca';
    font-style: normal;
    font-weight: 800;
    src: url('assets/fonts/lexend-deca-v21-latin_latin-ext-800.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }

  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Batuphat Script';
    font-style: normal;
    font-weight: 800;
    src: url('assets/fonts/Batuphat-Script.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }

body {
    margin: 0;
    font-family: "Lexend Deca" !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
