
  

body {
    background-color: #00C8F6 !important;
}

.ml-4 {
    margin-left: 12px;
}

.header-img {
    height: 400px;
    width: 100%;
    object-fit: cover;
}

@media screen and (max-width: 600px) {
    .header-img {
        height: 300px;
        width: 100%;
        object-fit: cover;
    }
  }

.category-button {
    text-transform: uppercase;
    color: #201A17;
    border-radius: 0.375rem 0 0.375rem 0 !important;
    cursor: pointer;
    font-weight: 600;
    font-size: 18px;
    border: 1px solid;
    border-color: #201A17;
}

.background-black {
    background-color: #000;
}

.header-text {
    color: #00C8F6;
    padding: 32px 0px 24px 0px;
    font-family: Batuphat Script;
    font-size: 48px;
}

.active-category {
    background-color: #EF5592;
    color: white;
    border-color: #EF5592;
}

.btn-back {
    color: #201A17 !important;
    background-color: rgba(0, 0, 0, 0);
    font-size: 22px !important;
    font-weight: 600 !important;
    font-family: "Batuphat Script" !important;
}

.btn-add-cart {
    border-radius: 0.375rem 0 0.375rem 0 !important;
    background-color: #EF5592 !important;
    color:white !important;
    font-weight: 700 !important;
    

}


.btn-filter {
    border-radius: 0.375rem 0 0.375rem 0 !important;
    background-color: #EF5592 !important;
    min-width: 127px;
    text-align: right !important;
}

.btn-add-cart:disabled {
    background-color: #EF5592 !important;

    border-color: #ffffff !important;
}

.sale-title {
    color: #EF5592;
    font-weight: 700;
    font-size: 36px;
    letter-spacing: 0.105em;
}

.new-price {
    font-weight: 700;
}

.countdown-sale {
    color: #201A17;
}

.countdown-title {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    text-transform: capitalize;
}

.countdown-content {
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 56px;
}

.countdown-component {
    width: 100px;
}
/* 
.product-image {
    filter: grayscale(1);
    transition: all 0.5s ease;
}

.product-image:hover {
    filter: grayscale(0);
} */


.product-image {
    height: 250px;
    object-fit: contain;
    padding-top: 12px;
}


@media screen and (max-width: 600px) {
    .product-image {
        height: 150px;
        
    }
}

.product-title {
    text-transform: uppercase;
    font-size: 1.1rem !important;
    line-height: 1.2em !important;
    flex-grow: 1;
    
}


@media screen and (max-width: 600px) {
    .product-title {
        font-size: 0.9rem !important;
    }

    .original-price, .new-price {
        font-size: 0.9rem !important;
    }
}
.card-body {
    padding: 0.9rem !important;
}

.original-price {
    text-decoration: line-through;
}

.product-card {
    border-radius: 0.375rem 0 0.375rem 0 !important;
    min-height: 100%;
}


.product-card-content {
    flex-direction: column;
    height: 100%;
}




.product-image-wrapper {
    position: relative;
}

.product-card-body {
    height: 150px !important;
}

@media screen and (max-width: 600px) {
    .product-card-body {
        height: 150px !important;
    }
    .product-title {
        font-size: 1rem;
    }
}

.product-image-badge {
    border-radius: 0.375rem 0 0.375rem 0 !important;
    position: absolute;
    top: -5px;
    left: -10px;
    background-color: #EF5592;
    color: white;
    padding: 0.5em;
    margin: 0;
    font-weight: 600;
    font-size: 20px;
    box-shadow: rgba(0, 0, 0, 0.05) 3px 6px 9px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.05) 3px 6px 9px;
}

.product-variant {
    border: 1px solid;
    margin-right: 8px;
    font-weight: 600;
    box-shadow: rgba(0, 0, 0, 0.1) 3px 6px 9px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.product-variant:hover {
    cursor: pointer;
}

.selected-variation {
    background-color: #EF5592;
    border-color: #EF5592;
}
